import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import {
  Table,
  Input,
  Button
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next"; // Import useTranslation

import ProductModal from "pages/Products/costCalculatorModal";

const OrderTable = (props) => {
  const { t } = useTranslation(); // Initialize translation function
  const { products } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const toggleModal = () => setModalOpen(!modalOpen);

  const shouldShowButton = (prds) => {
    const lendProduct = JSON.parse(prds.requirements);
    const mats = lendProduct[0].materials;
    const canlend = mats.filter((ppr) => ppr.canlended === true);

    if (canlend[0]) {
      const lendedid = canlend[0].id;
      const alreadylended = props.Loanproducts.some((product) => product.id === lendedid && product.originid === prds.id);
      return !alreadylended; // Return false if lendedid is already in Loanproducts
    }

    return true;
  };

  const handleCostPriceClick = (prds) => {
    setSelectedProduct(prds);
    setModalOpen(true); // Open the modal
  };

  const checkifloans = (prds) => {
    const lendProduct = JSON.parse(prds.requirements);
    if (lendProduct && lendProduct.length > 0) {
      const mats = lendProduct[0].materials;
      const canlend = mats.filter((ppr) => ppr.canlended === true);
      return !!canlend[0]; // Return true if canlend exists
    } else {
      return false;
    }
  };

  return (
    <>
      <Table className="align-middle mb-0">
        <thead>
          <tr>
            <th>{t('ITEM')}</th>
            <th>{t('NAME')}</th>
            <th>{t('SKU')}</th>
            <th>{t('LCOST')}</th>
            <th>{t('PRICE')}</th>
            <th>{t('Product VAT')}</th>
            <th>{t('UNITS')}</th>
            <th>{t('QUANTITY')}</th>
            <th>{t('TOTAL')}</th>
            <th>{t('VAT')}</th>
            <th>{t('Inc-VAT')}</th>
            <th>{t('Stock')}</th>
            <th>{t('ACTION')}</th>
            <th>{t('LOAN')}</th>
            <th>{t('DELETE')}</th>
          </tr>
        </thead>
        <tbody>
          {products && products.map((prds, i) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>{prds.name}</td>
              <td>{prds.sku}</td>
              <td onClick={() => handleCostPriceClick(prds)}
                  style={{ color: prds.costprice > prds.salesprice ? "red" : "green" }}>
                {Number(prds.costprice).toFixed(2)}
              </td>
              <td>
                <Input
                  value={prds.salesprice}
                  type="number"
                  style={{ minWidth: 80, backgroundColor: prds.costprice > prds.salesprice ? "red" : "#E4FEE4" }}
                  onChange={(e) => {
                    let value = e.target.value;
                    let newArr = [...products];
                    newArr[i].salesprice = value;
                    props.setproducts(newArr);
                  }}>
                </Input>
              </td>
              <td>{prds.vat}%</td>
              <td>{prds.unit}</td>
              <td>
                <Input style={{ maxWidth: 100 }} type="number"
                  onChange={(e) => {
                    props.products[i].ammount = e.target.value;
                    let value = e.target.value;
                    let newArr = [...products];
                    newArr[i].quantity = value;
                    newArr[i].totalPrdAmmount = (Number(value) * Number(prds.salesprice)).toFixed(2);
                    newArr[i].vatProducts = Number(Number(value) * Number(prds.salesprice) * Number(prds.vat / 100)).toFixed(2);
                    props.setproducts(newArr);
                  }}>
                </Input>
              </td>
              <td>{Number(prds.totalPrdAmmount).toFixed(2)}</td>
              <td><h5>{parseFloat(prds.vatProducts).toFixed(2)}</h5></td>
              <td><h5>{(Number(prds.totalPrdAmmount) + Number(prds.vatProducts)).toFixed(2)}</h5></td>
              <td>
                {prds.stock > prds.quantity && <h5 style={{ color: "green" }}>{prds.stock - prds.quantity}</h5>}
                {prds.stock < prds.quantity && <h5 style={{ color: "red" }}>{prds.stock - prds.quantity}</h5>}
              </td>
              <td>
                <Button onClick={() => props.checkHistory(prds)} color="light" size="sm">{t('History')}</Button>
              </td>
              <td>
                {prds.quantity > 0 && checkifloans(prds) && shouldShowButton(prds) &&
                  <Button onClick={() => {
                    const lendProduct = JSON.parse(prds.requirements);
                    const mats = lendProduct[0].materials;
                    const canlend = mats.filter((ppr) => ppr.canlended === true);
                    if (canlend[0]) {
                      canlend[0].quantity = prds.quantity;
                      canlend[0].originid = prds.id;
                    }

                    const updateProductCostPrice = async (productId) => {
                      try {
                        const newCostPrice = await props.getProducts(productId); // Replace with actual fetch operation
                        const updatedProducts = products.map((product) => {
                          if (product.id === prds.id) {
                            return { ...product, costprice: prds.costprice - newCostPrice[0].costprice };
                          }
                          return product;
                        });
                        props.setproducts(updatedProducts);
                      } catch (error) {
                        console.error('Error fetching new cost price:', error);
                      }
                    };

                    updateProductCostPrice(canlend[0].id);
                    props.setLoanproducts([...props.Loanproducts, canlend[0]]);
                  }} color="light" size="sm">
                    {t('Lend')}
                  </Button>
                }
              </td>
              <td>
                <Button onClick={() => props.setproducts(products.filter((item, ind) => ind !== i))} color="light" size="sm">{t('Delete')}</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ProductModal isOpen={modalOpen} toggle={toggleModal} product={selectedProduct} />
    </>
  );
};

OrderTable.propTypes = {
  products: PropTypes.array,
  Loanproducts: PropTypes.array,
  onGetInvoices: PropTypes.func,
  fixCosts: PropTypes.func,
  history: PropTypes.object,
  setproducts: PropTypes.func,
  setLoanproducts: PropTypes.func,
  getProducts: PropTypes.func,
  checkHistory: PropTypes.func
};

export default withRouter(OrderTable);
