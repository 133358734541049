

//LOGIN
export const DFKEY = "d980462ed5e2f8b0b6f444e82b490cad7e9da60cff44cf5e4b284d480a0969c5"
export const API_URL = window.location.hostname === '192.168.1.84'
  ? "http://192.168.1.84:8888/api/v2/"
  : "https://be.kerinoath.com/api/v2/";
  
export const KERINO = API_URL+"imprint/"
export const POST_LOGIN = API_URL+"user/session"
export const GET_TASKS = KERINO+"_table/tasks?filter=(status!=FINISHED)"
// export const GET_MATERIALS = KERINO+"_table/materials"
export const GET_TYPES = KERINO+"_table/taskTypes"
export const GET_PROVIDERS = KERINO+"_table/providers"
export const INVOICES = KERINO+"_table/invoices"
export const CREATE_TASK = KERINO+"_table/tasks"
export const GET_ORDERS = KERINO+"_table/custorders"

export const GET_CUSTOMERS = KERINO+"_table/customers"
export const CREATE_CUSTOMER = KERINO+"_table/customers"
export const GET_PRODUCTS = KERINO+"_table/products"
export const SALESPERSONS = KERINO+"_table/salespersons"
export const CREATE_PRODUCT = KERINO+"_table/products"
export const CREATE_ORDER = KERINO+"_table/custorders"
export const CREATE_LOAN = KERINO+"_table/stockloans"
export const GET_CODES= KERINO+"_table/managecodes"
export const PAYMENTS = KERINO+"_table/payments"