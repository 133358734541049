import React, { useMemo, useState, useEffect } from "react";
import PropTypes from 'prop-types';

// Import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { getPayments } from "../../helpers/api_helper";
import { Container, Row, Col, Spinner, Alert } from "reactstrap";
import { useTranslation } from "react-i18next"; // Import useTranslation

function PaymentsTable() {
    const { t } = useTranslation(); // Initialize translation function

    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch all payments
        const fetchPayments = async () => {
            try {
                const response = await getPayments();
                if (response) {
                    setPayments(response);
                } else {
                    setError(t("No payments found."));
                }
            } catch (err) {
                setError(t("Error loading payments."));
            } finally {
                setLoading(false);
            }
        };

        fetchPayments();
    }, [t]);

    // Define the columns for the table
    const columns = useMemo(
        () => [
            {
                Header: t('ID'), // Translated header
                accessor: 'id',
            },
            {
                Header: t('Entity Name'), // Translated header
                accessor: 'entity_name',
            },
            {
                Header: t('Transaction Type'), // Translated header
                accessor: 'bound',
                Cell: ({ cell: { value } }) => value === 'Inbound' ? t('Inbound') : t('Outbound'),
            },
            {
                Header: t('Payment Method'), // Translated header
                accessor: 'type',
                Cell: ({ cell: { value } }) => value === 'CASH' ? t('Cash') : value === 'CHECK' ? t('Check') : t('Card'),
            },
            {
                Header: t('Amount'), // Translated header
                accessor: 'ammount',
            },
            {
                Header: t('Series'), // Translated header
                accessor: 'series',
            },
            {
                Header: t('Date'), // Translated header
                accessor: 'date',
                Cell: ({ cell: { value } }) => new Date(value).toLocaleDateString(),
            },
            {
                Header: t('Bank Name'), // Translated header
                accessor: 'bankname',
            },
            {
                Header: t('Issuer'), // Translated header
                accessor: 'issuer',
            },
        ],
        [t]
    );

    // If still loading, show a spinner
    if (loading) {
        return (
            <Container fluid>
                <Spinner color="primary" />
            </Container>
        );
    }

    // If there's an error, show an alert
    if (error) {
        return (
            <Container fluid>
                <Alert color="danger">{error}</Alert>
            </Container>
        );
    }

    // Page title
    document.title = t("Payment List") + " | kerino - React Admin & Dashboard Template";

    return (
        <div className="page-content">
            <Container fluid>
                {/* Render Breadcrumbs */}
                <Breadcrumbs title={t("Payments")} breadcrumbItem={t("Payment List")} />

                <Row>
                    <Col lg={12}>
                        {/* Render the table */}
                        <TableContainer
                            rowClick={"/payment-overview"} // Navigate to payment overview page on row click
                            columns={columns}
                            data={payments}
                            isGlobalFilter={false}
                            isAddOptions={false}
                            customPageSize={50}
                            className="custom-header-css"
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

PaymentsTable.propTypes = {
    payments: PropTypes.any,
};

export default PaymentsTable;
