import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Container, Table, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from "reactstrap";
import { getTypes, createTaskType, updateTaskType, deleteTaskType } from "helpers/api_helper"; // Replace with your API helper

const TaskTypesPage = () => {
  const [taskTypes, setTaskTypes] = useState([]);
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTaskType, setNewTaskType] = useState({ name: "", description: "" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const taskTypesData = await getTypes();
        setTaskTypes(taskTypesData);
      } catch (error) {
        console.error('Error fetching task types:', error);
      }
    };

    fetchData();
  }, []);

  const toggleModal = (taskType = null) => {
    setSelectedTaskType(taskType);
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedTaskType(prev => ({ ...prev, [name]: value }));
  };

  const handleNewTaskChange = (e) => {
    const { name, value } = e.target;
    setNewTaskType(prev => ({ ...prev, [name]: value }));
  };

  const handleUpdateTaskType = async () => {
    try {
      const payload = {
        resource: [
          {
            id:selectedTaskType.id,
            // name: selectedTaskType.name,
            description: selectedTaskType.description,
            floor: selectedTaskType.floor,
          },
        ],
      };
  
      await updateTaskType(payload);
      const updatedTaskTypes = taskTypes.map(tt => tt.id === selectedTaskType.id ? selectedTaskType : tt);
      setTaskTypes(updatedTaskTypes);
      toggleModal();
    } catch (error) {
      console.error('Error updating task type:', error);
    }
  };

  const handleCreateTaskType = async () => {
    try {
      const payload = {
        resource: [
          {
            name: newTaskType.name,
            description: newTaskType.description,
            floor: newTaskType.floor,
          },
        ],
      };
  
      const createdTaskType = await createTaskType(payload);
      setTaskTypes([...taskTypes, createdTaskType]);
      // setNewTaskType({ name: "", description: "" });
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error creating task type:', error);
    }
  };
  
  const handleDeleteTaskType = async (taskTypeId) => {
    try {
      const payload = {
        resource: [
          {
            id: taskTypeId,
          },
        ],
      };
  
      await deleteTaskType(payload);
      const updatedTaskTypes = taskTypes.filter(tt => tt.id !== taskTypeId);
      setTaskTypes(updatedTaskTypes);
    } catch (error) {
      console.error('Error deleting task type:', error);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Task Types Management | Admin Dashboard</title>
        </MetaTags>
        <Container fluid>
          <h4>Sections Setup -- DONT TOUCH HERE IF YOU DONT KNOW WHAT IS </h4>

          <Table className="align-middle mb-0" style={{ marginTop: '20px' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {taskTypes.map((taskType) => (
                <tr key={taskType.id}>
                  <td>{taskType.name}</td>
                  <td>{taskType.description}</td>
                  <td>
                    <Button color="info" onClick={() => toggleModal(taskType)}>
                      Edit
                    </Button>{' '}
                    {/* <Button color="danger" onClick={() => handleDeleteTaskType(taskType.id)}>
                      Delete
                    </Button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Modal for editing task type */}
          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
              {selectedTaskType?.id ? "Edit Task Type" : "Create New Task Type"}
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  {/* <Label for="name">Name</Label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    value={selectedTaskType?.name || ""}
                    onChange={handleChange}
                  /> */}
                </FormGroup>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    type="textarea"
                    id="description"
                    name="description"
                    value={selectedTaskType?.description || ""}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="description">Floor</Label>
                  <Input
                    type="text"
                    id="floor"
                    name="floor"
                    value={selectedTaskType?.floor || ""}
                    onChange={handleChange}
                  />
                </FormGroup>
                <Button
                  color="primary"
                  onClick={selectedTaskType?.id ? handleUpdateTaskType : handleCreateTaskType}
                >
                  {selectedTaskType?.id ? "Update" : "Create"}
                </Button>
              </Form>
            </ModalBody>
          </Modal>

          {/* Form to create new task type */}
          <div style={{ marginTop: '40px' }}>
            <h5>Create New Task Type</h5>
            <Form inline>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  id="newName"
                  name="name"
                  value={newTaskType.name}
                  onChange={handleNewTaskChange}
                  placeholder="Enter task type name"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  id="newDescription"
                  name="description"
                  value={newTaskType.description}
                  onChange={handleNewTaskChange}
                  placeholder="Enter task type description"
                  required
                />
              </FormGroup>
              <Button color="success" onClick={handleCreateTaskType}>
                Create
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TaskTypesPage;
